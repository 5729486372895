import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { resetPassword1 } from "../APIservice/apiService";
import { useParams } from "react-router-dom";
const Reset = () => {
  const navigate = useNavigate();
  const gologin = () => {
    navigate("/login");
  };
  const { id }  = useParams();
//   console.log(id)
  const [formData, setFormData] = useState({
    password: "",
    Cpassword: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const resetPassword = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    const password = formData.password; // Access the password input value
    const confirmPassword = formData.Cpassword;
    const token = id
    const errors = {};
    if (!formData.password) {
        errors.password = "Password is required";
      } else if (formData.password.length < 8 || formData.password.length > 16) {
        errors.password = "Password must be between 8 and 16 characters";
      }
      if (formData.password !== formData.Cpassword) {
        errors.Cpassword = "Passwords does not matched";
      } else if (!formData.Cpassword) {
        errors.Cpassword = "Password is required";
      }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      const response = await resetPassword1(confirmPassword,password,token);
    //   console.log(response);
      if (response.data.message) {
        navigate('/login'); 
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
     event.preventDefault();
  };

  return (
    <>
      <div className="flex h-screen">
        <div className="hidden p-5 lg:flex items-center justify-center flex-1 bg-[#1F2937] text-black">
        <img
              className="h-full rounded-lg w-full"
              src="https://images.pexels.com/photos/17350364/pexels-photo-17350364/free-photo-of-a-computer-in-a-room.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
              alt=""
            />
         
        </div>

        <div className="w-full bg-[#111827] lg:w-1/2 flex items-center justify-center">
          <div className="max-w-lg bg-[#1F2937] rounded-xl w-full p-6">
            <h1 className="text-3xl font-semibold mb-6 text-white text-center">
              Reset Password
            </h1>
          
            <form className="space-y-4">
            
              
              <div>
                <label
                  for="password"
                  className="block text-sm font-medium text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    setFormErrors({ ...formErrors, password: "" }); // Clear the error message for password
                  }}
                  className="mt-1 bg-[#2F3D53] mb-6 p-2 text-white w-full border rounded-md "
                />
                {formErrors.password && (
                  <p className="text-red-500">{formErrors.password}</p>
                )}
              </div>

              <div>
                <label
                  for="Cpassword"
                  className="block text-sm font-medium text-white"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="Cpassword"
                  name="Cpassword"
                  value={formData.Cpassword}
                  onChange={(e) => {
                    setFormData({ ...formData, Cpassword: e.target.value });
                    setFormErrors({ ...formErrors, Cpassword: "" }); // Clear the error message for password
                  }}
                  className="mt-1 bg-[#2F3D53] mb-6 p-2 text-white w-full border rounded-md "
                />
                {formErrors.Cpassword && (
                  <p className="text-red-500">{formErrors.Cpassword}</p>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full bg-[#1A56DB] text-white p-2 rounded-md font-bold hover:bg-blue-200 hover:text-black focus:outline-none focus:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors duration-300"
                onClick={resetPassword}
                >
                 Submit
                </button>
              </div>
            </form>
            <div className="mt-4 text-sm text-white text-center">
              <p>
                Already have an account?{" "}
                <a className="text-blue-500 hover:text-blue-700 font-bold hover:underline border-white" onClick={gologin}>
                  Login here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
