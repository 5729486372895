import React, { useEffect, useState, useCallback } from "react";
import { Tooltip } from "flowbite-react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import TempModel from "../Model/templateModel";
import { cloneTemplate, deleteTemplate, getTemplates } from "../APIservice/apiService";
import {decryptId} from "../Encrypt/encode";
const Templates = () => {
  const { projectId } = useParams();

  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [project, setProject] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const handleCopy = useCallback(async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }, []);

  const fetchTemplates = useCallback(async (id) => {
    try {
      const response = await getTemplates(id);
      if (response) {
        setProject(response);
      }
      console.log(response,"templateresponse")
    } catch (error) {
      console.error("Fetching templates failed:", error);
      console.log(error)
      if(error.response.data == "Project not found"){
        navigate(`/projects`);
      }
    }
  }, []);

  const handleEditTemplate = useCallback((id) => {
    navigate(`/projects/${projectId}/template/${id}`);
  }, [navigate]);

  useEffect(() => {
    // console.log(projectId)
    // const id = decryptId(projectId)
    // console.log(id,'new')
    fetchTemplates(projectId);
  }, []);

  const handleConfirmation = useCallback((id) => {
    console.log(id,"ID DELETEDE")
    swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You want to delete this project!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await deleteTemplate(id);
              if (response) {
                toast.success("Template Deleted Successfully");
                fetchTemplates(projectId);
              }
            } catch (error) {
              console.error("Delete failed:", error);
            }
          }
        });
  }, [swalWithBootstrapButtons, fetchTemplates]);

  const EditTemplatesConfirmation = useCallback((id) => {
    console.log(id, "ID DELETE");
    swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          // text: "You want to edit this template sure! Bacause If you Edit than all o your old dynamic template is gone ",
          text: "you want to edit this template? Be aware that if you edit, all of your old dynamic template images will be lost.",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, edit it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            // Call the handleEditTemplate if confirmed
            handleEditTemplate(id);
          }
        });
  }, [swalWithBootstrapButtons, handleEditTemplate]);

  const handleClone = useCallback(async (id) => {
    try {
      const response = await cloneTemplate(id);
      if (response.id) {
        toast.success("Template Cloned Successfully");
        fetchTemplates(projectId);
      }
    } catch (error) {
      console.error("Clone failed:", error);
    }
  }, [fetchTemplates]);

  return (
      <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-4">
          <div className="mx-auto sm:px-6 lg:px-8">
            {/*<div className="flex justify-between mt-5">
              <h1 className="text-2xl sm:text-4xl mt-3 font-extrabold dark:text-white">
                {project ? `Project : ${project.name}` : "Please Create New Template"}
              </h1>
              <button
                  onClick={openModal}
                  type="button"
                  className="px-3 py-2 text-xs font-medium rounded-full text-center inline-flex items-center text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
                  // className="mt-2 w-48 h-10 px-2 text-base font-medium text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 rounded-full flex items-center"
              >
                <svg
                    className="w-5 h-5 mr-2 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14m-7 7V5" />
                </svg>
                CREATE TEMPLATE
              </button>
            </div>*/}
            <div className="flex justify-between py-6 items-center dashboard_h2">
              <h2 className="text-2xl w-[10rem] md:w-fit lg:text-4xl font-extrabold dark:text-white">
                {project ? `Project : ${project.name}` : "Please Create New Template"}
              </h2>
              <button
                  onClick={openModal}
                  type="button"
                  className="p-[10px] py-2.5 text-xs md:text-sm font-medium rounded-full inline-flex items-center text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
                  // className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-bold rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 inline-flex"
              >
                <svg
                    className="w-5 h-5 mr-2 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M5 12h14m-7 7V5"/>
                </svg>
                CREATE TEMPLATE
              </button>
            </div>
            {project && project.Templates && project.Templates.length > 0 ? (
                project.Templates.map((template) => (
                    <div className="grid gap-2 grid-cols-1 sm:grid-cols-1 w-full mt-5" key={template.id}>
                      <div
                          className="w-full p-2 bg-[#1F2937] hover:bg-gray-700 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                          <li className="py-3 sm:py-4">
                            <div className="flex lg:justify-between flex-col lg:flex-row">
                              <div className="flex">
                                {/* {console.log(`${template.thumbnail}?t=${new Date().getTime()}`)} */}
                                <img className="w-32 h-auto" src={`${template.thumbnail}?t=${new Date().getTime()}`}
                                     alt="Template Thumbnail"/>
                                <div className="flex-1 ms-4 items-center">
                                  <p className="overflow-auto text-sm sm:text-base md:text-3xl mb-3 font-extrabold text-white truncate max-w-[164px] sm:max-w-sm md:max-w-md lg:max-w-[17rem] xl:max-w-lg">{template.template_name}</p>
                                  <div
                                      className="inline-flex mt-2 items-center text-base font-semibold text-gray-900 dark:text-white">
                                    <button
                                        // onClick={() => handleEditTemplate(template.id)}
                                        onClick={()=> EditTemplatesConfirmation(template.id)}
                                        type="button"
                                        className="text-white bg-[#AC94FA] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                           className="w-3 h-3 sm:w-5 sm:h-5 md:w-4 md:h-4">
                                        <path
                                            d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z"></path>
                                      </svg>
                                    </button>
                                    <button
                                        onClick={() => handleConfirmation(template.id)}
                                        type="button"
                                        className="text-white bg-[#AC94FA] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      <svg className="w-3 h-3 sm:w-5 sm:h-5 md:w-4 md:h-4" aria-hidden="true"
                                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
                                      </svg>
                                    </button>
                                    <button
                                        onClick={() => handleClone(template.id)}
                                        type="button"
                                        className="text-white bg-[#AC94FA] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      <svg className="w-3 h-3 sm:w-5 sm:h-5 md:w-4 md:h-4" aria-hidden="true"
                                           xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                        <path fillRule="evenodd"
                                              d="M8 12.732A1.99 1.99 0 0 1 7 13H3v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2h-2a4 4 0 0 1-4-4v-2.268ZM7 11V7.054a2 2 0 0 0-1.059.644l-2.46 2.87A2 2 0 0 0 3.2 11H7Z"
                                              clipRule="evenodd"/>
                                        <path fillRule="evenodd"
                                              d="M14 3.054V7h-3.8c.074-.154.168-.3.282-.432l2.46-2.87A2 2 0 0 1 14 3.054ZM16 3v4a2 2 0 0 1-2 2h-4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-3Z"
                                              clipRule="evenodd"/>
                                      </svg>
                                    </button>
                                    <button
                                        onClick={() => navigate(`/templates-embed/${template.template_uuid}`)}
                                        type="button"
                                        className="text-white bg-[#AC94FA] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      <svg className="w-3 h-3 sm:w-5 sm:h-5 md:w-4 md:h-4" aria-hidden="true"
                                           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2" d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full mt-5 sm:mt-5 max-w-[30rem] md:max-w-[23rem]">
                                <div className="relative">
                                  <input
                                      id="npm-install-copy-button"
                                      type="text"
                                      className="col-span-6 bg-gray-50 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                      value={template.template_uuid}
                                      disabled
                                      readOnly
                                  />
                                  <button
                                      data-copy-to-clipboard-target="npm-install-copy-button"
                                      data-tooltip-target="tooltip-copy-npm-install-copy-button"
                                      className="absolute end-2 top-1/2 -translate-y-1/2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center"
                                      onClick={() => handleCopy(template.template_uuid)}
                                      onMouseEnter={() => setIsHovering(true)}
                                      onMouseLeave={() => setIsHovering(false)}
                                  >
                                    <Tooltip content={copySuccess ? "Copied!" : isHovering ? "Copy" : ""}>
                                <span id="default-icon">
                                  <svg className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                       fill="currentColor" viewBox="0 0 18 20">
                                    <path
                                        d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                                  </svg>
                                </span>
                                      <span id="success-icon" className="hidden inline-flex items-center">
                                  <svg className="w-3.5 h-3.5 text-blue-700 dark:text-blue-500" aria-hidden="true"
                                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                  </svg>
                                </span>
                                    </Tooltip>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                ))
            ) : (
                <div
                    className="text-center w-full h-auto mt-3  flex justify-center   rounded-lg shadow sm:p-8">
                  <h1 className="text-2xl sm:text-4xl mt-3 font-extrabold dark:text-white dark:bg-gray-800 dark:border-gray-700 py-7 px-10 rounded-lg">
                    No Data Found
                  </h1>
                </div>
            )}
          </div>
        </div>
        <TempModel isOpen={isModalOpen} onClose={closeModal}/>
      </>
  );
};

export default Templates;
