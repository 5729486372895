import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Navbar,
    Alignment,
    EditableText,
    Button,
} from '@blueprintjs/core';
import styled from 'raeditor/utils/styled';
import { useProject } from '../project';
import { useNavigate } from "react-router-dom";
import {DownloadButton} from "./download-button";
import {nameCharLimit} from "../CharLimit";

const NavbarContainer = styled('div')`
    white-space: nowrap;

    @media screen and (max-width: 500px) {
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100vw;
    }
`;

const NavInner = styled('div')`
    @media screen and (max-width: 500px) {
        display: flex;
    }
`;

const MyNavbar = observer(({ store }) => {
    const project = useProject();
    const [isSaving, setIsSaving] = useState(false);
    const [isTemplateUUID, setIsTemplateUUID] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            if (project.template_uuid) {
                setIsTemplateUUID(true);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [project.template_uuid]);

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await project.save();
        } catch (error) {
            console.error("Save failed:", error);
        }
        setIsSaving(false);
    };
 

    const handleAPI = async () => {
        await handleSave();
        navigate(`/templates-embed/${project.template_uuid}`);
        // window.location.href = `/templates-embed/${project.template_uuid}`;
    }

    const [errorMessage, setErrorMessage] = useState('');
    const handleNameChange = (name) => {
        const nameCharLimit = 30;
        // Check for leading space and trailing space conditions
        if (name.startsWith(' ')) {
            setErrorMessage('Template name cannot start with a space.');
        } else if (name.trim() === "") {
            window.project.name = name; // Allow setting the name to empty
            setErrorMessage('Template name cannot be empty or just spaces.');
        }else if (name.length > nameCharLimit) {
            setErrorMessage(`Template name cannot exceed ${nameCharLimit} characters`);
        }
        else {
            window.project.name = name;
            window.project.requestSave();
            setErrorMessage(''); // Clear error message
        }
    };
    
    return (
        <NavbarContainer className="bp5-navbar">
            <NavInner>
                <Navbar.Group align={Alignment.LEFT}>
                    <div
                        style={{
                            paddingLeft: '10px',
                            maxWidth: '200px',
                        }}
                    >
                        <EditableText
                            value={window.project.name}
                            placeholder="Design name"
                            className="min-w-[150px] md:min-w-[250px] mr-6 text-sm font-medium text-gray-900 dark:text-white"
                            // onChange={(name) => {
                            //     window.project.name = name;
                            //     window.project.requestSave();
                            // }}
                            onChange={handleNameChange}
                        />
                         {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    </div>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Button
                        icon="floppy-disk"
                        text={isSaving ? "Saving..." : "Save"}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1 me-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900"
                        intent="primary"
                        onClick={handleSave}
                        disabled={isSaving || errorMessage !== ''}
                    />
                    <Button
                        icon="code"
                        text="API Integration"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1 me-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900"
                        intent="primary"
                        onClick={handleAPI}
                        disabled={!isTemplateUUID}
                    />
                    <DownloadButton store={store} />
                </Navbar.Group>
            </NavInner>
        </NavbarContainer>
        
    );
});

export default MyNavbar;
