import React, { useContext, useEffect, useState, useCallback } from "react";
import { Tabs } from "flowbite-react";
import Header from "../header/header";
import { UserContext } from "../context/UserContext";
import { useSnap } from "raeditor/canvas/use-transformer-snap";
import { getUserCreditsAndTemplates } from "../APIservice/apiService";
const DashboardTab = () => {
  const { UserName, updateUser } = useContext(UserContext);
  const [credits, setCredits] = useState(UserName.credits);
  const [totalCredits, setTotalCredits] = useState(0);
  const [plan, setPlan] = useState(UserName.plan);
  const [response, setResponse] = useState(UserName.credits);

  const fetchTemplates = useCallback(async () => {
    try {
      const response = await getUserCreditsAndTemplates();
      if (response) {
        console.log(response);
      }
      console.log(response, "templateresponse");
      setResponse(response);
      setCredits(response.remainingCredits);
      setPlan(response.planName);

      setTotalCredits(response.totalPlanCredits);
    } 
    catch (error) {
      // console.error("Fetching templates failed:", error);
      // console.log(error)
      // if(error.response.data == "Project not found"){
      //   navigate(`/projects`);
      // }
    }
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const [selectedImage, setSelectedImage] = useState(null); // State to hold the selected image

  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the selected image
  };

  const closeModal = () => {
    setSelectedImage(null); // Close the modal
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-6">
        <div className="flex justify-between items-center mx-auto py-6 px-0 sm:px-6 lg:px-8 dashboard_h2">
          <h2 className="font-extrabold text-gray-600 dark:text-gray-200 leading-tight">
            Dashboard
          </h2>
        </div>
        <div className="mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 gap-x-5 mb-6">
            <a
              href="#"
              className="block max-w-xl p-6 bg-white rounded-lg shadow-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <p className="font-normal text-gray-700 dark:text-gray-400 mb-2">
                AVAILABLE CREDITS
              </p>
              <h5 className="mb-2 text-4xl font-bold tracking-tight text-gray-600 dark:text-white inline-flex items-center align-middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 h-8 mr-2 text-gray-600 dark:text-white"
                  fill="currentColor"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M360-400h400L622-580l-92 120-62-80-108 140Zm-40 160q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z"></path>
                </svg>
                {/*{credits} / {totalCredits}*/}
                {credits > totalCredits
                  ? `${totalCredits} / ${totalCredits}`
                  : `${credits} / ${totalCredits}`}
              </h5>
            </a>
            <a
              href="#"
              className="block max-w-xl p-6 bg-white rounded-lg shadow-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <p className="font-normal text-gray-700 dark:text-gray-400 mb-2">
                YOUR PLAN
              </p>
              <h5 className="mb-2 text-4xl font-bold tracking-tight text-gray-600 dark:text-white inline-flex items-center align-middle">
                <svg
                  className="w-8 h-8 mr-2 text-gray-600 dark:text-white"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                >
                  <path d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z"></path>
                </svg>
                {plan}
              </h5>
            </a>
            {/*  <a
              href="#"
              className="block max-w-sm p-6 bg-white rounded-lg shadow-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <p className="font-normal text-gray-700 dark:text-gray-400 mb-2">
                NEXT BILLING PERIOD
              </p>
              <h5 className="mb-2 text-4xl font-bold tracking-tight text-gray-600 dark:text-white inline-flex items-center align-middle">
                -
              </h5>
            </a>*/}
          </div>
          <div className="block p-6 bg-white rounded-lg shadow-lg  dark:bg-gray-800 dark:border-gray-700 ">
            <h5 className="mb-3 text-xl font-bold tracking-tight text-gray-600 dark:text-white inline-flex items-center align-middle">
              USAGE
            </h5>
            <div className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700">
              <div
                className="h-4 bg-gradient-to-br from-purple-600 to-blue-500 rounded-full"
                // style={{ width: `${(credits - totalCredits) * 100}%` }}
                // style={{ width: `${((totalCredits - credits) / totalCredits) * 100}%` }} // Calculate remaining credits percentage
                //   style={{width: `${totalCredits - credits}%`}}
                style={{
                  width: `${((totalCredits - credits) / totalCredits) * 100}%`,
                }} // Calculate remaining credits percentage
              ></div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mx-auto py-6 px-0 sm:px-6 lg:px-8 dashboard_h2">
          <h2 className="font-extrabold text-gray-400 dark:text-gray-100 leading-tight">
            Your Dynamic Generated Images 
          </h2>
        </div>

        {/* <div className="mx-auto sm:px-6 lg:px-8">
          <div className="overflow-x-auto mx-auto max-w-7xl rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-2 py-3 text-white text-center">
                    #
                  </th>
                  <th scope="col" className="px-2 py-3 text-white text-center">
                    Template Name
                  </th>
                  <th scope="col" className="px-2 py-3 text-white text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {response?.templatesWithDownloadUrl?.length > 0 ? (
                  response.templatesWithDownloadUrl.map((template, index) => (
                    <tr
                      key={template.template_id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap text-center dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <td className="px-2 py-4 text-center">
                        {template?.template_name}
                      </td>
                      <td className="px-2 py-4 justify-center flex flex-col sm:flex-row">
                        <button
                          onClick={() => handleImageClick(template?.image_url)}
                          className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-4 py-1 mb-2 sm:mb-0 sm:mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        >
                          View
                        </button>
                        <button
                          onClick={() =>
                            (window.location.href = template?.downloadUrl)
                          }
                          className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-4 py-1 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      className="px-6 py-4 text-center text-gray-500"
                    >
                      No any Generated template
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div> */}
<div className="mx-auto sm:px-6 lg:px-8">
  <div className="overflow-x-auto mx-auto max-w-7xl rounded-lg">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-2 py-3 text-white text-center">
            #
          </th>
          <th scope="col" className="px-2 py-3 text-white text-center">
            Template Name
          </th>
          <th scope="col" className="px-2 py-3 text-white text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {response?.templatesWithDownloadUrl?.length > 0 ? (
          response.templatesWithDownloadUrl.map((template, index) => (
            <tr
              key={template.template_id}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <th
                scope="row"
                className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap text-center dark:text-white"
              >
                {index + 1}
              </th>
              <td className="px-2 py-4 text-center">
                {template?.template_name}
              </td>
              <td className="px-2 py-4 justify-center items-center flex flex-col sm:flex-row">
                <button
                  onClick={() => handleImageClick(template?.image_url)}
                  className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-xs px-2 py-1 mb-2 w-20 sm:w-auto sm:px-4 sm:py-1 sm:text-sm sm:mb-0 sm:mr-2 whitespace-nowrap dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  View
                </button>
                <button
                  onClick={() => (window.location.href = template?.downloadUrl)}
                  className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-xs px-2 py-1 w-20 sm:w-auto sm:px-4 sm:py-1 sm:text-sm whitespace-nowrap dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  Download
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="3" className="px-6 py-4 text-center text-gray-500">
              No any Generated template
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div>




      </div>
      
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeModal}
        >
          <div
            className="bg-white p-4 rounded relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeModal}
              className="absolute top-1 right-1 p-1 text-black-700 bg-white rounded-full"
            >
              {/* Cross icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              src={selectedImage}
              alt="Selected"
              className="max-w-full max-h-[80vh] w-auto object-contain"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardTab;
