import React, { useEffect,useContext} from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import { RaeditorContainer, SidePanelWrap, WorkspaceWrap } from "raeditor";
import { ZoomButtons } from "raeditor/toolbar/zoom-buttons";
import { SidePanel, DEFAULT_SECTIONS, SizeSection } from "raeditor/side-panel";
import { Workspace } from "raeditor/canvas/workspace";
// for specific url 
import { isAlive } from "mobx-state-tree";
import { getImageSize } from "raeditor/utils/image";
import { getCrop } from "raeditor/utils/image";

// ending here
import { Tooltip } from "./sections/tooltip";
import { PagesTimeline } from "raeditor/pages-timeline";
import { setTranslations } from "raeditor/config";
import { UploadPanel } from "./sections/upload-section";
import { loadFile } from "./file";
import { StableDiffusionSection } from "./sections/stable-diffusion-section";
import { IconsSection } from "./sections/icons-section";
import { ShapesSection } from "./sections/shapes-section";
import { MyDesignsSection } from "./sections/my-designs-section";
import { DefaultTemplatesSection } from "./sections/default-templates-section";
import { PhotosSection } from "./sections/photos-section";
import { LayersSection } from "./sections/layers-panel";
import { TextSection } from "./sections/text-section";
import { useProject } from "./project";
import fr from "./translations/fr";
import en from "./translations/en";
import id from "./translations/id";
import ru from "./translations/ru";
import ptBr from "./translations/pt-br";
import Topbar from "./topbar/topbar";
import Toolbar from "./sections/toolbar";
import { RatingStarSection } from "./sections/ratingStarSection";
import { AiImageGenrate } from "./sections/AiImageGenrate";
import { ImageClipSection } from "./sections/image-clip-panel";
import { decryptId } from "./Encrypt/encode";
import { getProfile,updatePassword,updateProfile } from "./APIservice/apiService";
import { UserContext } from "./context/UserContext";


let DEFAULTSECTIONS = DEFAULT_SECTIONS.filter(
  (section) => section.name !== "templates"
);
DEFAULTSECTIONS = DEFAULTSECTIONS.filter((section) => section.name !== "icons");
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(
  (section) => section.name !== "elements"
);
DEFAULTSECTIONS = DEFAULTSECTIONS.filter((section) => section.name !== "size");
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(
  (section) => section.name !== "photos"
);
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(
  (section) => section.name !== "layers"
);
DEFAULTSECTIONS = DEFAULTSECTIONS.filter(
  (section) => section.name !== "text"
); 
DEFAULTSECTIONS.unshift(ShapesSection);
DEFAULTSECTIONS.unshift(TextSection);
DEFAULTSECTIONS.push(LayersSection);
DEFAULTSECTIONS.push(RatingStarSection);
DEFAULTSECTIONS.push(ImageClipSection);
DEFAULTSECTIONS.push(AiImageGenrate);
// DEFAULTSECTIONS.push(StableDiffusionSection);

DEFAULTSECTIONS.unshift(IconsSection);
DEFAULTSECTIONS.unshift(PhotosSection);
// DEFAULTSECTIONS.unshift(MyDesignsSection);
DEFAULTSECTIONS.unshift(DefaultTemplatesSection);
DEFAULTSECTIONS.unshift(SizeSection);
// DEFAULTSECTIONS.unshift(SizeSection);
// DEFAULTSECTIONS.unshift(UploadPanel);
// UploadPanel

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight - 64);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const App = observer(({ store }) => {
  const navigate = useNavigate();
  const { projectId, templateId } = useParams();
  const { projectId1, imageurl } = useParams();
  const decodedImageUrl = decodeURIComponent(imageurl);
  console.log("Image URL:", decodedImageUrl);
  const project = useProject();
  const height = useHeight();

  // const match = useMatch("/projects/:projectId/template/:templateId");

  // useEffect(() => {
  //   // Function to be called when back button is pressed
  //   const handleBackButton = (event) => {
  //     if (match) {
  //       console.log("Back button pressed on the template page.");
  //       // Perform your custom logic here, for example:
  //       // navigate("/another-page"); // Redirect to another page
  //     }
  //   };

  //   // Listen for the browser's back button
  //   window.addEventListener("popstate", handleBackButton);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("popstate", handleBackButton);
  //   };
  // }, [match, navigate]);
  const { UserName, updateUser } = useContext(UserContext);
  React.useEffect(() => {
    if (project.language.startsWith("fr")) {
      setTranslations(fr);
    } else if (project.language.startsWith("id")) {
      setTranslations(id);
    } else if (project.language.startsWith("ru")) {
      setTranslations(ru);
    } else if (project.language.startsWith("pt")) {
      setTranslations(ptBr);
    } else {
      setTranslations(en);
    }
  }, [project.language]);

  // const loadImage = async (imageurl) => {
  //   const { width: imageWidth, height: imageHeight } =  getImageSize(
  //     imageurl
  //   );
  //   console.log(imageWidth,imageHeight,"height and width is here")
  //   await store.history.transaction(async () => {
  //     const img = store.activePage?.addElement({
  //       type: "image",
  //       src: imageurl,
  //       width: imageWidth,
  //       height: imageHeight,
  //     });
  
  //     if (isAlive(img)) {
  //       await img.set({ src: imageurl });
  //     }
  //   });
  // };

  const changeCanvasSize = (newWidth, newHeight) => {
    // Update the store with the new dimensions
    store.setSize(newWidth, newHeight); // Assuming setSize is a method in your store
};

  const handleImageClick = async (url, pos, element) => {
    console.log(url, "URL IS HERE");

    // Fetch the image size (replace `getImageSize` with a suitable method if needed)
    const { width: imageWidth, height: imageHeight } = await getImageSize(
      url
    );
    console.log("Calling this function", imageWidth);
    console.log("Calling this function", imageHeight);

    // If an element exists and is editable, apply cropping and set the image
    if (element && element.type === "image" && element.contentEditable) {
      const crop = getCrop(element, {
        width: imageWidth,
        height: imageHeight,
      });
      element.set(Object.assign({ src: url }, crop));
      return; // End the function here since we've updated the existing element
    }

    

    console.log(
      `Calculated dimensions: width = ${imageWidth}, height = ${imageHeight}`
    );

    // Determine position of the image if not provided
    const x = (pos?.x || store.width / 2) - imageWidth / 2;
    const y = (pos?.y || store.height / 2) - imageHeight / 2;

    changeCanvasSize(imageWidth, imageHeight);
    // Add the image to the store with the calculated dimensions
    store.history.transaction(async () => {
      const img = store.activePage?.addElement({
        type: "image",
        src: url,
        width: imageWidth,
        height: imageHeight,
        // x: x,
        // y: y,
      });

      if (isAlive(img)) {
        await img.set({ src: url });
      }
    });
  };


  React.useEffect(() => {
    project.clear();
    project.name = "Untitled Design";
    project.projectId = projectId;
    if(imageurl){
     
      // Call the new function to handle image loading
      handleImageClick(imageurl);
    }
    // if(imageurl){
    //   const { width: imageWidth, height: imageHeight } =  getImageSize(
    //     imageurl
    //   );
    //   console.log(imageWidth,imageHeight,"height and width is here")
    //   store.history.transaction(async () => {
    //     const img = store.activePage?.addElement({
    //       type: "image",
    //       src: imageurl,
    //       width: imageWidth,
    //       height: imageHeight,
    //     });

    //     if (isAlive(img)) {
    //       await img.set({ src: imageurl });
    //     }
    //   });
    // }
    
    let defaultTemplateId = localStorage.getItem("templateId");
    let customSize = localStorage.getItem("customSize");
    if (templateId) {
      project.firstLoad(templateId, projectId);
    } else if (defaultTemplateId) {
      project.defaultLoadById(defaultTemplateId);
      localStorage.removeItem("templateId");
    } else if (customSize) {
      customSize = customSize.split("x");
      project.store.setSize(parseInt(customSize[0]), parseInt(customSize[1]));
      localStorage.removeItem("customSize");
    }
  }, []);
  // console.log(project.store.setSize,"SIZE IS HERE")
  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  const handleSave = async () => {
    try {
      await project.save();
    } catch (error) {
      console.error("Save failed is here app.js how can i do that :", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSave();
      console.log("Calling Is Here");
    }, 500);

    return () => clearTimeout(timer);
  }, []);


 


  return (
    <div
      className="bp5-dark"
      style={{
        width: "99vw",
        height: height + "px",
        display: "flex",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
    >
      <Topbar store={store}    />
      <div style={{ height: "calc(100% - 50px)" }}>
        <RaeditorContainer className="raeditor-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={DEFAULTSECTIONS} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} />
            <Workspace store={store} components={{ Tooltip }} />
            <ZoomButtons store={store} />
            <PagesTimeline store={store} />
          </WorkspaceWrap>
          {/* <button style={{position:"absolute",left:"1px"}}onClick={() => store.addPage()}>Add Page</button> */}
        </RaeditorContainer>
      </div>
    </div>
  );
});

export default App;
