import axios from 'axios';
import Cookies from 'js-cookie';

export const API_BASE_URL = `${process.env.REACT_APP_API_URL}api`;

const setBearerTokenInterceptor = (config) => {
    // const token = localStorage.getItem('token');
    const token = Cookies.get('token');
    // const token = sessionStorage.getItem('token')
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
};

const api = axios.create({
    baseURL: API_BASE_URL
});

api.interceptors.request.use(
    setBearerTokenInterceptor,
    (error) => Promise.reject(error)
);

export default api;