import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { getTotalClientRect } from "raeditor/utils/math";
import { PositionPicker } from "raeditor/toolbar/position-picker";
import { DuplicateButton } from "raeditor/toolbar/duplicate-button";
import { RemoveButton } from "raeditor/toolbar/remove-button";
import { DataFieldButton } from "./data-field-button";
import { extendToolbar } from "raeditor/toolbar/element-container";
import {Button, Navbar, NavbarGroup} from "@blueprintjs/core";
import { Html } from "react-konva-utils";
import {FaDatabase} from "react-icons/fa";

function findParentWithClass(element, className) {
    return element.classList.contains(className) ? element : element.parentElement ? findParentWithClass(element.parentElement, className) : null;
}

export const Tooltip = observer(({ store, page, components, stageRef }) => {
    const boundingBox = getTotalClientRect(store.selectedShapes);
    const areAllShapesOnPage = store.selectedShapes.every(shape => shape.page === page);
    const tooltipRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const hasCroppedImages = store._hasCroppedImages;

    useEffect(() => {
        const handleDragStart = () => setIsDragging(true);
        const handleDragEnd = () => setIsDragging(false);

        const stage = stageRef?.current;
        stage?.on("dragstart", handleDragStart);
        stage?.on("dragend", handleDragEnd);

        const transformer = stage?.findOne("Transformer");
        transformer?.on("transformstart", handleDragStart);
        transformer?.on("transformend", handleDragEnd);

        return () => {
            stage?.off("dragstart", handleDragStart);
            stage?.off("dragend", handleDragEnd);
            transformer?.off("transformstart", handleDragStart);
            transformer?.off("transformend", handleDragEnd);
        };
    }, [stageRef]);

    const [fitToViewport, setFitToViewport] = useState({ fit: true, needCalculate: false, token: Math.random() });

    useEffect(() => {
        if (store.selectedElements.length !== 0) {
            setFitToViewport({ fit: true, needCalculate: true, token: Math.random() });
        }
    }, [store.selectedElements, isDragging]);

    useEffect(() => {
        setTimeout(() => {
            if (!tooltipRef.current) return;
            if (!fitToViewport.needCalculate) return;

            const workspaceContainer = findParentWithClass(tooltipRef.current, "raeditor-workspace-container");
            if (!workspaceContainer) return;

            const workspaceRect = workspaceContainer.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            const topOffset = tooltipRect.top - workspaceRect.top;
            const bottomOffset = tooltipRect.bottom - workspaceRect.top;

            if (topOffset < 20 && fitToViewport.fit) {
                setFitToViewport({ fit: false, needCalculate: false, token: fitToViewport.token });
            } else if (bottomOffset - workspaceRect.height > -20 && !fitToViewport.fit) {
                setFitToViewport({ fit: true, needCalculate: false, token: fitToViewport.token });
            }
        }, 10);
    }, [fitToViewport.needCalculate, tooltipRef.current, fitToViewport.token]);

    useEffect(() => {
        const workspaceInner = findParentWithClass(stageRef.current.content, "raeditor-workspace-inner");
        const handleScroll = () => {
            if (store.selectedElements.length !== 0) {
                setFitToViewport({ fit: true, needCalculate: true, token: Math.random() });
            }
        };

        workspaceInner?.addEventListener("scroll", handleScroll);

        return () => {
            workspaceInner?.removeEventListener("scroll", handleScroll);
        };
    }, [stageRef, store.selectedElements.length]);

    if (store.selectedShapes.length === 0 || isDragging || !areAllShapesOnPage || store.activePage !== page || hasCroppedImages) {
        return null;
    }

    const PositionPickerComponent = components?.Position || PositionPicker;
    const DuplicateButtonComponent = components?.Duplicate || DuplicateButton;
    const RemoveButtonComponent = components?.Remove || RemoveButton;
    const selectedElementType = store.selectedElements[0].type;
    const extendedToolbar = extendToolbar({ components, type: selectedElementType, usedItems: [] });
    const transformerRotation = stageRef.current?.findOne("Transformer")?.rotation() || 0;
    let offset = 30;

    if (Math.abs(transformerRotation) < 5 && fitToViewport.fit) {
        offset = 80;
    } else if (Math.abs(transformerRotation) > 160 && !fitToViewport.fit) {
        offset = 80;
    }

    return (
        <Html
            transformFunc={transform => {
                const x = boundingBox.x + boundingBox.width / 2;
                const y = fitToViewport.fit ? boundingBox.y * transform.scaleY - offset : boundingBox.y * transform.scaleY + boundingBox.height * transform.scaleY + offset;
                return { ...transform, x: transform.x + x * transform.scaleX, y: transform.y + y, scaleX: 1, scaleY: 1 };
            }}
            divProps={{ style: { pointerEvents: "none", zIndex: 9 } }}
        >
            <div ref={tooltipRef} style={{ pointerEvents: "none" }}>
                <Navbar style={{ padding: "2px", borderRadius: "5px", height: "34px", transform: "translate(-50%, -50%)", pointerEvents: "auto" }}>
                    <NavbarGroup style={{ height: "30px" }}>
                        {extendedToolbar.map(item => {
                            const Component = components[item];
                            return <Component key={item} elements={store.selectedElements} element={store.selectedElements[0]} store={store} />;
                        })}
                        <DataFieldButton store={store} />
                        <DuplicateButtonComponent store={store} />
                        <RemoveButtonComponent store={store} />
                        <PositionPickerComponent store={store} />
                    </NavbarGroup>
                </Navbar>
            </div>
        </Html>
    );
});
