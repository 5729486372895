import React from "react";

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px",color:"white" }}>
      <h1>404 - Page Not Found</h1>
      <p>YEH PAGE 😊 HI NAHI HAI 😊 BHiiiiiiiiiiiiiiDu</p>
      Yoo Rutvik bhai ni daya dakan ne khay 6ee
    </div>
  );
};

export default NotFoundPage;
