import React from 'react';
import { observer } from 'mobx-react-lite';
import { NumericInput, Navbar, Alignment, HTMLSelect } from '@blueprintjs/core';
import ColorPicker from 'raeditor/toolbar/color-picker';
import { unstable_registerToolbarComponent } from 'raeditor/config';

// Function to generate SVG content for the rating stars
const generateStarsSVG = (element, updatedCustom, elementWidth) => {
    const { value, scale, spacing, colorFull, colorEmpty } = updatedCustom;
    const starSize = element.height; // Fixed star size

    const stars = Array.from({ length: parseInt(scale, 10) }, (_, i) => {
        const fillColor = i < Math.floor(value) ? colorFull : colorEmpty;
        const halfStar = value - Math.floor(value) >= 0.5 && i === Math.floor(value);

        return halfStar
            ? `<svg x="${i * (starSize + spacing)}" y="0" width="${starSize}" height="${starSize}" viewBox="0 0 50 50">
                <defs>
                    <linearGradient id="half-fill">
                        <stop offset="50%" stop-color="${colorFull}" />
                        <stop offset="50%" stop-color="${colorEmpty}" stop-opacity="1" />
                    </linearGradient>
                </defs>
                <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z" fill="url(#half-fill)" />
               </svg>`
            : `<svg x="${i * (starSize + spacing)}" y="0" width="${starSize}" height="${starSize}" viewBox="0 0 50 50">
                <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z" fill="${fillColor}" />
               </svg>`;
    }).join('');

    return `<svg xmlns="http://www.w3.org/2000/svg" width="${elementWidth}" height="${starSize}">${stars}</svg>`;
};

// Rating Star Toolbar Component
export const RatingStarToolbar = observer(({ store }) => {
    const element = store.selectedElements[0];

    if (!element || !element.custom) return null;

    const handleChange = (key, value) => {
        const updatedCustom = { ...element.custom, [key]: value };
        const starSize = element.height;
        const elementWidth = (starSize + updatedCustom.spacing) * updatedCustom.scale - updatedCustom.spacing;
        element.set({ custom: updatedCustom });
        const svgContent = generateStarsSVG(element, updatedCustom, elementWidth);
        element.set({ width: elementWidth });
        element.set({ src: `data:image/svg+xml;utf8,${encodeURIComponent(svgContent)}` });
    };

    return (
        <Navbar.Group align={Alignment.LEFT}>
            <div style={{ marginRight: '10px', display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: '10px' }}>Default Value:</div>
                <NumericInput
                    onValueChange={(value) => handleChange('value', value)}
                    value={element.custom.value}
                    min={0}
                    max={parseInt(element.custom.scale, 10)}
                    stepSize={0.5}
                    style={{ maxWidth: "50px" }}
                />
            </div>

            <div style={{ marginRight: '10px', display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: '10px' }}>Scale:</div>
                <HTMLSelect
                    value={element.custom.scale}
                    onChange={(e) => handleChange('scale', e.target.value)}
                >
                    <option value="5">0-5</option>
                    <option value="10">0-10</option>
                </HTMLSelect>
            </div>

            <div style={{ marginRight: '10px', display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: '10px' }}>Spacing:</div>
                <NumericInput
                    onValueChange={(value) => handleChange('spacing', value)}
                    value={element.custom.spacing}
                    min={0}
                    stepSize={10}
                    style={{ maxWidth: "50px" }}
                />
            </div>

            <div style={{ marginRight: '10px', display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: '10px' }}>Color Full:</div>
                <ColorPicker
                    value={element.custom.colorFull}
                    onChange={(colorFull) => handleChange('colorFull', colorFull)}
                    store={store}
                />
            </div>

            <div style={{ marginRight: '10px', display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: '10px' }}>Color Empty:</div>
                <ColorPicker
                    value={element.custom.colorEmpty}
                    onChange={(colorEmpty) => handleChange('colorEmpty', colorEmpty)}
                    store={store}
                />
            </div>
        </Navbar.Group>
    );
});

unstable_registerToolbarComponent('rating-star', RatingStarToolbar);
